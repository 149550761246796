import React from 'react';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Text,
  Stack,
  Image,
  useColorModeValue,
  Button,
  Link,
  HStack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import shopster from "../../shopster.PNG";
import easychat from "../../chat-app.png";
import hbnb from "../../hbnb.PNG";
import simple_shell from "../../simple_shell.PNG";
import routinery from "../../routinery.png";

const MotionBox = motion(Box);

const ProjectCard = ({ title, desc, image, github, web=null }) => {
  const SetColorModeValue = (color1, color2) => {
    return useColorModeValue(color1, color2);
  };

  return (
    <MotionBox
      whileHover={{ y: -5 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box
        maxW={'full'}
        w={'full'}
        bg={SetColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}>
        <Box
          h={'250px'}
          bg={'gray.100'}
          mt={0}
          mx={0}
          mb={0}
          pos={'relative'}>
          <Image
            src={image}
            alt={title}
            objectFit="cover"
            w="full"
            h="full"
          />
        </Box>
        <Stack p={6}>
          <Heading
            color={SetColorModeValue('gray.700', 'white')}
            fontSize={'2xl'}
            fontFamily={'body'}>
            {title}
          </Heading>
          <Text color={SetColorModeValue('gray.600', 'gray.400')}>
            {desc}
          </Text>
          <HStack py={2}>
            <Link href={github} isExternal>
              <Button colorScheme="teal">Github</Button>
            </Link>
            { web &&
            <Link href={web} isExternal>
              <Button colorScheme="teal">Live Preview</Button>
            </Link>
          }
          </HStack>
        </Stack>
      </Box>
    </MotionBox>
  );
};

const projects = [
  {
    title: 'Routinery',
    desc: "This is a modern fitness tracking web application where users can manage and track their workouts. It's built using React for the frontend interface and NestJS with MongoDB for the backend server. The app follows a clean client-server architecture with proper user authentication and a polished UI using TailwindCSS.",
    image: routinery,
    github: 'https://github.com/xhuve/routinery',
  },
  {
    title: 'Shopster',
    desc: 'This full-stack application integrates a React front-end with a Node.js/Express back-end to provide user authentication, product management, and payment handling. Redux is used for managing global state across components, ensuring efficient data flow. MongoDB serves as the database to manage products, orders, and wishlists.',
    image: shopster,
    github: 'https://github.com/xhuve/shopster',
  },
  {
    title: 'EasyChat',
    desc: 'This full-stack application features a React front-end built with Vite and an Express back-end, supporting secure authentication and real-time messaging. Tailwind CSS is used for responsive UI styling, ensuring a sleek and modern interface. The app provides a seamless communication experience with fast, secure interactions.',
    image: easychat,
    github: 'https://github.com/xhuve/chat-app-clone-V2',
    web: "https://chat.xhuve.me",
  },
  {
    title: 'Holberton AirBnB',
    desc: 'This dynamic web app is built with Flask, HTML, CSS, and JavaScript, featuring a MySQL database for data management. The architecture follows a repository pattern for data persistence and modularity, while a facade pattern coordinates API routes, models, and storage. RESTful API routes in Flask ensure clean separation of concerns for efficient application logic.',
    image: hbnb,
    github: 'https://github.com/xhuve/holbertonschool-hbnb',
  },
  {
    title: 'Simple Shell',
    desc: 'This Unix shell, written in C, allows users to run commands either directly or from files. It handles Linux processes efficiently and works in both interactive mode (where users enter commands one at a time) and non-interactive mode (running commands from a script). The shell is simple to use, offering a basic interface for executing common Unix commands.',
    image: simple_shell,
    github: 'https://github.com/xhuve/holbertonschool-simple_shell',
  },
];

export default function Works() {
  const SetColorModeValue = (color1, color2) => {
    return useColorModeValue(color1, color2);
  };

  return (
    <Box id="works" bg={SetColorModeValue('gray.50', 'gray.900')} py={20}>
      <Container maxW={'7xl'}>
        <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'} mb={10}>
          <Heading fontSize={'3xl'}>My Projects</Heading>
          <Text color={SetColorModeValue('gray.600', 'gray.400')} fontSize={'xl'}>
            Here are some of my recent works
          </Text>
        </Stack>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} px={4}>
          {projects.map((project, index) => (
            <ProjectCard key={index} {...project} />
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
