import React from 'react';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import Header from './components/layout/Header';
import Hero from './components/sections/Hero';
import Skills from './components/sections/Skills';
import Education from './components/sections/Education';
import Works from './components/sections/Works';
import Contact from './components/sections/Contact';

function App() {
  return (
    <ChakraProvider>
      <CSSReset />
      <Header />
      <Hero />
      <Skills />
      <Education />
      <Works />
      <Contact />
    </ChakraProvider>
  );
}

export default App;
