import React from 'react';
import {
  Container,
  Stack,
  Box,
  Heading,
  Text,
  Button,
  useColorModeValue,
  Center
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { ReactTyped } from "react-typed";


const MotionBox = motion(Box);

export default function Hero() {
  return (
    <Container maxW={'7xl'} id="about">
      <Center
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: 'column', md: 'row' }}>
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <MotionBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
              <Text
                as={'span'}
                position={'relative'}
                _after={{
                  content: "''",
                  width: 'full',
                  height: '20%',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  bg: useColorModeValue('teal.200', 'teal.500'),
                  zIndex: -1,
                }}>
                Hi, I'm Ardit
              </Text>
              <br />
              <Text fontSize={{ base: 'xl', sm: '2xl', lg: '4xl' }} as={'span'} color={'teal.400'}>
                Im a {' '}
              <ReactTyped
                strings={[
                  "junior software developer.",
                  "web developer.",
                  "problem solver.",
                  "motivated learner.",
                ]}
                typeSpeed={40}
                backSpeed={50}
                loop
              />
              </Text>
            </Heading>
            <Text color={'gray.500'} fontSize={'xl'}>
              A passionate developer crafting beautiful and functional web experiences.
            </Text>
          </MotionBox>
          <Center
            gap='10px'
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: 'column', sm: 'row' }}>
            <Button
              rounded={'full'}
              size={'lg'}
              fontWeight={'normal'}
              px={6}
              colorScheme={'teal'}
              bg={'teal.400'}
              _hover={{ bg: 'teal.500' }}
              as={'a'}
              cursor={'pointer'}
              onClick={() => {
                document.getElementById('works').scrollIntoView({
                  behavior: 'smooth',
                });
              }}>
              View My Work
            </Button>
            <Button
              rounded={'full'}
              size={'lg'}
              fontWeight={'normal'}
              px={6}
              as={'a'}
              href="https://xhuve.me/my_cv.pdf"
              target="_blank"
              >
              Check Out My CV
            </Button>
          </Center>
        </Stack>
      </Center>
    </Container>
  );
}
