import {
  Container,
  Flex,
  Box,
  Heading,
  IconButton,
  VStack,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { BsGithub, BsLinkedin } from 'react-icons/bs';
import { TbFileCv } from "react-icons/tb";

export default function Contact() {

  return (
    <Box id="contact" bg={useColorModeValue('gray.100', 'gray.700')}>
      <Container maxW="7xl" py={20} centerContent>
        <Flex direction={{ base: 'column', md: 'row' }} width="full">
          <Box
            bg={useColorModeValue('white', 'gray.800')}
            borderRadius="lg"
            p={8}
            width="full"
            maxW={{ base: 'full', md: '7xl' }}
            boxShadow="lg"
          >
            <VStack spacing={5} width="full">
              <Heading fontSize={'3xl'} mb={4}>
                Get in Touch
              </Heading>

              <HStack
                mt={4}
                spacing={5}
                px={5}
                alignItems="flex-start"
              >
                <IconButton
                  aria-label="github"
                  variant="ghost"
                  size="lg"
                  isRound={true}
                  _hover={{ bg: 'teal.400', color: 'white' }}
                  icon={<BsGithub size="28px" />}
                  as="a"
                  href="https://github.com/xhuve"
                  target="_blank"
                />
                <IconButton
                  aria-label="linkedin"
                  variant="ghost"
                  size="lg"
                  isRound={true}
                  _hover={{ bg: 'teal.400', color: 'white' }}
                  icon={<BsLinkedin size="28px" />}
                  as="a"
                  href="https://www.linkedin.com/in/ardit-xhuveli-376a92183/"
                  target="_blank"
                />
                <IconButton
                  aria-label="email"
                  variant="ghost"
                  size="lg"
                  isRound={true}
                  _hover={{ bg: 'teal.400', color: 'white' }}
                  icon={<TbFileCv size="34px" />}
                  as="a"
                  href="https://xhuve.me/my_cv.pdf"
                  target="_blank"
                />
              </HStack>
            </VStack>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}
