import { useColorModeValue, Text } from '@chakra-ui/react';
import React from 'react';

const NavLink = ({ children }) => (
  <Text
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    cursor={'pointer'}
    className="navlink"
    onClick={() => {
      document.getElementById(children.toLowerCase()).scrollIntoView({behavior: 'smooth'});
    }}
  >
    {children}
  </Text>
);

export default NavLink;
