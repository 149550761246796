import React from 'react';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { 
  FaCode, 
  FaReact, 
  FaDatabase, 
  FaNodeJs,
  FaServer,
  FaTools
} from 'react-icons/fa';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const Feature = ({ title, text, icon }) => {
  const SetColorModeValue = (color1, color2) => {
    return useColorModeValue(color1, color2);
  };

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Stack
        align={'center'}
        justify={'center'}
        direction={'column'}
        bg={SetColorModeValue('white', 'gray.800')}
        boxShadow={'lg'}
        rounded={'xl'}
        p={6}
        spacing={3}
        transition={'all 0.3s ease'}
        _hover={{
          transform: 'translateY(-5px)',
        }}>
        <Icon as={icon} w={10} h={10} color={'teal.400'} />
        <Text fontWeight={600}>{title}</Text>
        <Text color={SetColorModeValue('gray.600', 'gray.400')} fontSize={'sm'} textAlign={'center'}>
          {text}
        </Text>
      </Stack>
    </MotionBox>
  );
};

export default function Skills() {
  const SetColorModeValue = (color1, color2) => {
    return useColorModeValue(color1, color2);
  };

  const skills = [
    {
      title: 'Core Languages',
      text: 'C, Python, JavaScript, TypeScript, C#. Experienced in both system-level and application development.',
      icon: FaCode,
    },
    {
      title: 'Frontend Technologies',
      text: 'React, Redux for state management, Modern HTML/CSS, Responsive Design, Tailwind CSS.',
      icon: FaReact,
    },
    {
      title: 'Backend Technologies',
      text: 'Node.js, Express.js, NestJS. Experience in building RESTful APIs and server-side applications.',
      icon: FaNodeJs,
    },
    {
      title: 'Database Systems',
      text: 'MySQL, MongoDB, Database Design, ORM Implementation, Query Optimization.',
      icon: FaDatabase,
    },
    {
      title: 'API Development',
      text: 'RESTful Architecture, API Design Patterns, Authentication/Authorization, Middleware Implementation.',
      icon: FaServer,
    },
    {
      title: 'Development Tools',
      text: 'Git Version Control, Docker Containerization, Linux Environment.',
      icon: FaTools,
    },
  ];

  return (
    <Box id="skills" bg={SetColorModeValue('gray.50', 'gray.900')} py={20}>
      <Container maxW={'7xl'}>
        <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'} mb={10}>
          <Heading fontSize={'3xl'}>Technical Skills</Heading>
          <Text color={SetColorModeValue('gray.600', 'gray.400')} fontSize={'xl'}>
            Programming languages and technologies
          </Text>
        </Stack>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10} px={4}>
          {skills.map((skill, index) => (
            <Feature
              key={index}
              title={skill.title}
              text={skill.text}
              icon={skill.icon}
            />
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
