import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Stack,
  HStack,
  Card,
  CardHeader,
  CardBody,
  Flex,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionCard = motion(Card);

export default function Education() {
  const education = [
    {
      school: 'University of Tirana',
      degree: 'Informatics',
      period: '2022-2025',
      description: 'The Bachelor program in Informatics aims to equip students with foundational knowledge in informatics and related fields. Graduates will be prepared for advanced studies or technical roles in IT management and production across public and private sectors.',
      logo: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2F12vite.com%2Fwp-content%2Fuploads%2F2018%2F08%2FUniversity_of_Tirana_logo.svg_-1536x1536.png&f=1&nofb=1&ipt=edd9cd28c420d4b76b44cf3878c3a72708da4c1abe7369f47b7f3db6509f020e&ipo=images'
    },
    {
      school: 'Holberton School',
      degree: 'Foundation of Computer Science',
      period: 'Feb-Nov 2024',
      description: 'Holberton School is a Computer Science institution founded in Silicon Valley to address the educational gap for aspiring software engineers. Its intensive 9-month program covers a wide range of foundational topics, including low-level programming languages like C, object-oriented programming, and web development, providing students with the skills needed to thrive in the tech industry.',
      logo: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcourse_report_production.s3.amazonaws.com%2Frich%2Frich_files%2Frich_files%2F5169%2Fs300%2Finstagram-feed180.jpg&f=1&nofb=1&ipt=04a6f4907ddcfd4c174aac3ad28911a4e87f61cd5dde0bc87007364f1462e77e&ipo=images'
    }
  ];

  const SetColorModeValue = (color1, color2) => {
    return useColorModeValue(color1, color2);
  }

  return (
    <Box id="education" bg={SetColorModeValue('gray.50', 'gray.900')} py={20}>
      <Container maxW={'7xl'}>
        <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'} mb={10}>
          <Heading fontSize={'3xl'}>Education</Heading>
          <Text color={SetColorModeValue('gray.600', 'gray.400')} fontSize={'xl'}>
            My academic journey and qualifications
          </Text>
        </Stack>

        <Stack spacing={4} px={4}>
          {education.map((edu, index) => (
            <MotionCard
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              size="lg"
              variant="outline"
              bg={SetColorModeValue('white', 'gray.800')}
            >
              <CardHeader>
                <Flex justifyContent="space-between" alignItems="center">
                  <HStack spacing={4}>
                    <Image
                      boxSize="50px"
                      objectFit="contain"
                      src={edu.logo}
                      alt={edu.school}
                    />
                    <Box>
                      <Text fontSize="xl" fontWeight="bold">
                        {edu.school}
                      </Text>
                      <Text color={SetColorModeValue('gray.600', 'gray.400')}>{edu.degree}</Text>
                    </Box>
                  </HStack>
                  <Text color={SetColorModeValue('gray.500', 'gray.400')} fontWeight="medium">
                    {edu.period}
                  </Text>
                </Flex>
              </CardHeader>
              <CardBody>
                <Text color={SetColorModeValue('gray.600', 'gray.400')}>{edu.description}</Text>
              </CardBody>
            </MotionCard>
          ))}
        </Stack>
      </Container>
    </Box>
  );
}
